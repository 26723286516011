.chest {
    height: 200px;
    width: 200px;
    object-fit: contain;
}

.chests {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 50px;
}

.button {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.start {
    width: 200px;
    font-size: 30px;
    color: white;
    background-color: green;
    border: none;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
}

.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.title {
    color: white;
    text-align: center;
    font-size: 2em;
    margin-top: 20px;
}

.prize-text {
    color: white;
    text-align: center;
    font-size: 1.5em;
    margin-top: 20px;
}

.info {
    text-align: center;
}