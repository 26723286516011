.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    padding: 10px;
}

.soonImg {
    max-width: 90%;
    height: auto;
}

.soon {
    font-size: 50px;
    margin-top: 20px;
    text-align: center;
}