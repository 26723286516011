 * {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
     background-color: #0e1621;
     color: #fff;
     font-family: Arial, sans-serif;
 }



 .app-container {
     display: flex;
     flex-direction: column;
 }

 .st0 {
     fill: none;
     stroke: #fff;
     stroke-width: 2;
     stroke-linecap: round;
     stroke-linejoin: round;
     stroke-miterlimit: 10;
 }