.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal__head {
    margin-bottom: 25px;
}

.modal__content {
    padding: 30px;
    border-radius: 8px;
    text-align: center;
}

.modal__button {
    margin: 10px;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}