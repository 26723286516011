.home__container {
    max-width: 100%;
    margin: 0 auto;
}

.home__content {
    width: 100%;
    overflow-y: scroll;
    padding-bottom: 50px;
}

.home__item {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.home__owner {
    padding-top: 5px;
    padding-bottom: 10px;
}

.home__img {
    width: 346px;
    height: 212px;
    margin: 0 auto;
    margin-bottom: 3px;
}

.home__button {
    border: 1px solid #2c2c2c;
    border-radius: 10px;
    padding: 12px;
    width: 124px;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #898888;
}

.home__button__available {
    border: 1px solid #2c2c2cab;
    border-radius: 8px;
    padding: 12px;
    width: 211px;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    background-color: #1b1a5588;
    color: #f5f5f5;
}